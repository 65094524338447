import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/"
import Hero from "../components/ServicesPage/Hero"
import Divider from "../components/ServicesPage/Divider"
import Trusted from "../components/Trusted/schedule"
import "../assets/css/normalize.css"
import "../assets/css/custom.css"
import FeaturedImage from "../assets/img/integra-featured-image.png"

export default function Careers() {
  return (
    <>
      <Helmet
        title="Accounting, customer support, call center, virtual assistants, telemarketing, transcription, IT and Web development"
        meta={[
          {
            name: `description`,
            content:
              "Do you have the right resources to help your business grow? Our list of services includes Accounting, bookkeeping, customer support, call center, virtual assistants, telemarketing, transcription, IT and Web development. We provide these services to businesses across US, UK, Canada, Europe, Australia and Asian countries..",
          },
          {
            name: "keywords",
            content:
              "Business services, business support, business outsourcing services, Accounting, bookkeeping, customer support, call center, virtual assistants, telemarketing, transcription, IT and Web development",
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Accounting, customer support, call center, virtual assistants, telemarketing, transcription, IT and Web development"
        />
        <meta
          property="og:description"
          content="Do you have the right resources to help your business grow? Our list of services includes Accounting, bookkeeping, customer support, call center, virtual assistants, telemarketing, transcription, IT and Web development. We provide these services to businesses across US, UK, Canada, Europe, Australia and Asian countries."
        />
        <meta property="og:image" content={FeaturedImage} />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta
          name="twitter:title"
          content="Accounting, customer support, call center, virtual assistants, telemarketing, transcription, IT and Web development"
        />
        <meta
          name="twitter:description"
          content="Do you have the right resources to help your business grow? Our list of services includes Accounting, bookkeeping, customer support, call center, virtual assistants, telemarketing, transcription, IT and Web development. We provide these services to businesses across US, UK, Canada, Europe, Australia and Asian countries."
        />
        <meta name="twitter:image" content={FeaturedImage}></meta>
      </Helmet>
      <Layout>
        <main>
          <Hero />
          <Divider />
          <Trusted />
        </main>
      </Layout>
    </>
  )
}
