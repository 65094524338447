import React from "react"
import Divider from "../../../assets/img/divider.svg"

import Styled from "./styled.module.css"

function Hero() {
  return (
    <section className={Styled.section}>
      <div className={`${Styled.heroWrapper} container`}>
        <div className={Styled.heroDescription}>
          <div className={Styled.titleWrapper}>
            <h1 className={`${Styled.heroTitle} md-title bold`}>
              Work on big ideas, without the busy work
            </h1>
            <p className={`${Styled.heroSubtitle} lg-text`}>
              We provide a broad spectrum of tailored services designed to
              address the unique needs of a client organization.
            </p>
          </div>
        </div>
      </div>
      <img
        alt="Hero Divider"
        width="100%"
        className={Styled.divider}
        src={Divider}
      ></img>
    </section>
  )
}

export default Hero
