import React from "react"
import { Link } from "gatsby"
import Styled from "./styled.module.css"
import Illustration from "../../../assets/img/illustration.svg"
import Illustration2 from "../../../assets/img/illustration-2.svg"
import Illustration3 from "../../../assets/img/illustration-3.svg"
import Stockholm from "../../Icon/stockholm-2"
import Stockholm3 from "../../Icon/stockholm-3"
import Stockholm4 from "../../Icon/stockholm-4"
import Thunder from "../../../assets/img/thunder.svg"
import Group from "../../../assets/img/group.svg"
import Desktop from "../../../assets/img/desktop.svg"

function Hero() {
  return (
    <section>
      <div className="container">
        <div className={Styled.tabWrapper}>
          <a href="#outsourcing" className={Styled.tabCard}>
            <img alt="Group" src={Group}></img>
            <p className="sm-text color-secondary-blue">
              Business process outsourcing
            </p>
          </a>
          <a href="#automation" className={Styled.tabCard}>
            <img alt="Thunder" src={Thunder}></img>
            <p className="sm-text color-secondary-blue">
              Robotic process Automation
            </p>
          </a>
          <a href="#software" className={Styled.tabCard}>
            <img alt="Desktop" src={Desktop}></img>
            <p className="sm-text color-secondary-blue">Software & Design</p>
          </a>
        </div>
        <section id="outsourcing" className={Styled.secondWrapper}>
          <div className={Styled.textWrapper}>
            <h2 className={Styled.subtitle}>BUSINESS PROCESS OUTSOURCING</h2>
            <h1 className="md-title bold">
              Here at Integra, we work <br />
              as a remote part of your team.
            </h1>
            <p className="md-text">
              Our BPO and KPO outsourcing services are designed to provide you
              the added advantages of a skilled workforce aimed at reducing
              company expenses and operational costs; allowing you to increase
              profits, productivity and efficiency. BPO involves dealing with
              business processes of human resources and training, financial
              recording, data entry and more, while KPO deals with the
              accumulation of knowledge, insight and market research.
            </p>
          </div>
          <img
            alt="illustration"
            className={Styled.illustration}
            src={Illustration2}
          ></img>
          <div className={Styled.cardWrapper}>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Virtual Assistants</h1>
                <p className="sm-text">
                  Multi-skilled professionals with university degrees who plug
                  in to any process in any firm to deliver value.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Quantity Take-off Estimation</h1>
                <p className="sm-text">
                  You will get a complete list of materials with quantities and
                  locations along with the measurements used for the take off.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Accounting</h1>
                <p className="sm-text">
                  We can close books, prepare financial reports review
                  outstanding payables, receivables and check cash flows.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Call Center & Customer Support</h1>
                <p className="sm-text">
                  We offer 24/7 customer support staff to handle your inbound
                  and outbound communications.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Property Preservation Backoffice</h1>
                <p className="sm-text">
                  Initial securing, snow removal, grass cut, winterization,
                  interior cleaning, mold remediation, exterior trash & more.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Data Analytics / Data Processing</h1>
                <p className="sm-text">
                  Text or numeric data entry, forms/ invoice processing, OCR and
                  data conversion, we deliver high quality results for every
                  project.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Content Moderation</h1>
                <p className="sm-text">
                  We identify and remove offensive content, and keep your
                  website, social media channels and communities safe.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Revenue Cycle Management</h1>
                <p className="sm-text">
                  From performance management to identifying revenue leakage,
                  payer insights to KPI monitoring, we provide insights.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">3D CGI Animation</h1>
                <p className="sm-text">
                  We specialize in creating photo realistic computer-generated
                  imagery to clients seeking a robust solution to market.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm3 />
              </span>
              <div>
                <h1 className="green">Customised BPO Services</h1>
                <p className="sm-text">
                  We provide a broad spectrum of tailored services designed to
                  address the unique needs of a client organization.
                </p>
              </div>
            </div>
          </div>
          {/* <div className={Styled.grid}>
            <div className={Styled.titleWrapper}>
              <h1 className="sm-title bold">
                Looking to upgrade to a scalable <br /> alternative to your internal workforce.
              </h1>
              <a className={`explore sm-text bold`} href="/#">
                Start free trial
             </a>
            </div>
          </div> */}
          <div className={Styled.textCardWrapper}>
            <p className="sm-text">Start small. Outsource just one task</p>
            <p className="sm-text">Flexible contracts. Both hourly & monthly</p>
            <p className="sm-text">Cancel anytime. No questions, no charges</p>
            <p className="sm-text">Scale fast or slow, we’re ready</p>
          </div>
          <div className={Styled.grid}>
            <div className={Styled.titleWrapper}>
              <a className={`explore sm-text bold`} href="/#">
                <Link to="/contact">Start free trial</Link>
              </a>
            </div>
          </div>

          {/* <div className={Styled.workflowWrapper}>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className="sm-title bold">Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className="sm-title bold">Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className="sm-title bold">Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
          </div> */}
          {/* <div className={Styled.divider}></div> */}
        </section>
        <section id="automation" className={Styled.wrapper}>
          <div className={Styled.textWrapper}>
            <h2 className={Styled.subtitle}>ROBOTIC PROCESS AUTOMATION</h2>
            <h1 className="md-title bold">
              Bot automation for all
              <br /> accounting processes
            </h1>
            <p className="md-text">
              Since 2004, Integra has specialized in providing bookkeeping,
              accounting, taxation and payroll services for the USA, UK,
              Australia and Europe. We work well with bookkeeping, CPA & CA
              firms of all sizes. We also have experience in working with
              business owners from companies in various industries. We strongly
              believe in using our teams and the bots we build to deliver.
            </p>
          </div>
          <img
            alt="illustration"
            className={Styled.illustration}
            src={Illustration}
          ></img>
          <div className={Styled.cardWrapper}>
            <div className={Styled.card}>
              <span>
                <Stockholm />
              </span>
              <div>
                <h1>Bookkeeping</h1>
                <p className="sm-text">
                  Bank and credit card statements, bills, payroll,
                  reconciliation on any accounting platform you use..
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm />
              </span>
              <div>
                <h1>Accounting bot</h1>
                <p className="sm-text">
                  End-to-end bot powered accounting including invoice processing
                  on top of your existing accounting platform.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm />
              </span>
              <div>
                <h1>Accounting & taxes</h1>
                <p className="sm-text">
                  Qualified accountants to handle accrual accounting, tax (US,
                  UK, Europe), and management accounting.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm />
              </span>
              <div>
                <h1>Reconciliation bot</h1>
                <p className="sm-text">
                  Flexible reconciliation engine to automatically reconcile any
                  2 sets of data.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm />
              </span>
              <div>
                <h1>Payroll team</h1>
                <p className="sm-text">
                  Monthly/weekly payroll processing service for UK and US
                  businesses.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm />
              </span>
              <div>
                <h1>Global VAT bot</h1>
                <p className="sm-text">
                  Perfect tool for global ecom retailers to automatically
                  calculate and file taxes.
                </p>
              </div>
            </div>
          </div>
          <div className={Styled.grid}>
            {/* <div className={Styled.titleWrapper}>
              <h1 className="sm-title bold">
                Stress-free, scalable accounting to <br /> increase your bottom line
              </h1>
              <a className={`explore sm-text bold`} href="/#">
                Get a 1 hour trial
              </a>
            </div> */}
            <div className={Styled.textCardWrapper}>
              <p className="sm-text">
                Eliminate wasted hours on repetitive tasks
              </p>
              <p className="sm-text">Get your ROI in less than 3-6 months</p>
              <p className="sm-text">
                No need to change existing infrastructure
              </p>
              <p className="sm-text">Solutions available for every budget</p>
            </div>
            <div className={Styled.titleWrapper}>
              {/* <h1 className="sm-title bold">
                Stress-free, scalable accounting to <br /> increase your bottom line
              </h1> */}

              <a className={`explore sm-text bold`} href="/#">
                <Link to="/contact">Get a 1 hour trial</Link>
              </a>
            </div>
          </div>
          {/* <div className={Styled.workflowWrapper}>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className="sm-title bold">Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className="sm-title bold">Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className="sm-title bold">Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
          </div>
          <div className={Styled.divider}></div> */}
        </section>

        <section id="software" className={Styled.thirdWrapper}>
          <div className={Styled.textWrapper}>
            <h2 className="xs-text bold">SOFTWARE DEVELOPMENT & DESIGN</h2>
            <h1 className="md-title bold">
              Web, mobile and software applications that will allow you to gain
              market share.
            </h1>
            <p className="md-text">
              For the full-cycle product development, we generate a team, which
              includes a project manager, biz analyst, UI/UX designer, team
              lead, front and back-end software developers, DevOps, and QA
              engineer. These specialists are chosen according to the customer’s
              requirements in terms of their expertise, qualifications and
              skills. In collaboration with the product owner and customer-side
              stakeholders, we produce effective, actual, and profitable
              software products.
            </p>
          </div>
          <img
            alt="illustration"
            className={Styled.illustration}
            src={Illustration3}
          ></img>
          <div className={Styled.cardWrapper}>
            <div className={Styled.card}>
              <span>
                <Stockholm4 />
              </span>
              <div>
                <h1 className="dark-green">
                  Web / Cloud Software Applications
                </h1>
                <p className="sm-text">
                  You have an idea, we have the tools to bring it to life with
                  our custom software application development team.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm4 />
              </span>
              <div>
                <h1 className="dark-green">Content Management Systems (CMS)</h1>
                <p className="sm-text">
                  We work on Magento, Wordpress, Shopify, Prestashop and almost
                  every other major CMS platform for both admin tasks and new
                  projects.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm4 />
              </span>
              <div>
                <h1 className="dark-green">Mobile Application Development</h1>
                <p className="sm-text">
                  Bring your business right on to your customers phone with our
                  mobile application development team.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm4 />
              </span>
              <div>
                <h1 className="dark-green">Web Design & Development</h1>
                <p className="sm-text">
                  Present your business artfully with a handcrafted website that
                  looks great on desktop and any mobile device
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm4 />
              </span>
              <div>
                <h1 className="dark-green">Logo & Graphic Design</h1>
                <p className="sm-text">
                  Share a design brief with us. Our design service quality and
                  rates are among the best in the market.
                </p>
              </div>
            </div>

            <div className={Styled.card}>
              <span>
                <Stockholm4 />
              </span>
              <div>
                <h1 className="dark-green">E-Commerce Development</h1>
                <p className="sm-text">
                  Your products deserve a great store. We create a store that
                  you can manage on your own with minimal help.
                </p>
              </div>
            </div>
            <div className={Styled.card}>
              <span>
                <Stockholm4 />
              </span>
              <div>
                <h1 className="dark-green">Digital Marketing</h1>
                <p className="sm-text">
                  Create and monitor your digital presence on Google, Facebook,
                  Instagram and any other social media platform.
                </p>
              </div>
            </div>
          </div>
          <div className={Styled.grid}>
            {/* <div className={Styled.titleWrapper}>
              <h1 className="sm-title bold">
                You dream up the requirement,<br /> we'll build it for you.
              </h1>
              <a className={`explore sm-text bold`} href="/#">
                Talk to a business analyst now
              </a>
            </div> */}
            <div className={Styled.textCardWrapper}>
              <p className="sm-text">Fixed cost or flexible pricing models</p>
              <p className="sm-text">On-time delivery, every tems</p>
              <p className="sm-text">
                Free support included post project completion
              </p>
              <p className="sm-text">
                Team of Designers, developers, writers & marketers
              </p>
            </div>
            <div className={Styled.titleWrapper}>
              {/* <h1 className="sm-title bold">
                You dream up the requirement,<br /> we'll build it for you.
              </h1> */}
              <a className={`explore sm-text bold`} href="/#">
                <Link to="/contact">Talk to a business analyst now</Link>
              </a>
            </div>
          </div>
          {/* <div className={Styled.workflowWrapper}>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className={Styled.cardTitle}>Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className={Styled.cardTitle}>Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
            <div className={Styled.worflowCard}>
              <div className={Styled.workflowCardText}>
                <h1 className={Styled.cardTitle}>Testimonial</h1>
                <p className="sm-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliqua
                  ac volutpat, viverra magna risus aliquam massa. Ac aliquam ac
                  volutpat, viverra magna risus.
                </p>
              </div>
              <footer>George, Ministry of Design</footer>
            </div>
          </div>
          <div className={Styled.divider}></div> */}
        </section>
      </div>
    </section>
  )
}

export default Hero
