import React from "react"
import { Link } from "gatsby"
import Styled from "./trusted.module.css"
import Button from "../Button"
function Trusted() {
  return (
    <section className={Styled.sectionSchedule} id="trusted">
      <div className={`container ${Styled.trustedWrapper}`}>
        <h1 className={`${Styled.title} lg-title bold`}>
          Schedule a free <br /> expert session.
        </h1>
        <h2 className={`${Styled.text} md-text`}>
          We understand that every project is different and requires an
          individual approach. Find out which software solutions are the right
          fit for your project and how working with us like.
        </h2>
        <Link to="/contact">
          <Button size="large">Schedule now</Button>
        </Link>
      </div>
    </section>
  )
}

export default Trusted
