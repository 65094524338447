import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.667 10.667a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667zm0 17.333a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667zM7.333 28a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667z"
        fill="#0096B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.333 10.667a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667zm7.334-5.334h2.666a1.333 1.333 0 110 2.667h-2.666a1.333 1.333 0 110-2.667zm0 18.667h2.666a1.333 1.333 0 110 2.667h-2.666a1.333 1.333 0 010-2.667zm-8-10.667c.736 0 1.333.597 1.333 1.334v2.666a1.333 1.333 0 11-2.667 0v-2.666c0-.737.597-1.334 1.334-1.334zm18.666 0c.737 0 1.334.597 1.334 1.334v2.666a1.333 1.333 0 01-2.667 0v-2.666c0-.737.597-1.334 1.333-1.334z"
        fill="#0096B7"
      />
    </svg>
  )
}

export default SvgComponent
